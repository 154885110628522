
import '../styles/index.css'
import * as React from "react"
import { graphql, useStaticQuery, PageProps } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import arraySort from "array-sort"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Card from "../components/blog/card"
import Header from "../components/common/pageHeader"
import Newsletter from "../components/common/newsletter"
import FilteredPosts from "../components/blog/filteredPosts"
import CategoryHeader from "../components/blog/categoryHeader"

import FeaturedHero from "../images/featured-hero.png"
import oddBg from "../images/odd-background.png"
import leftOddDeco from "../images/odd-left-decoration.png"
import rightEvenDeco from "../images/even-right-deco.png"
import evenDots from "../images/even-dots.png"
import { isBrowser } from '../utils'

const IndexPage: React.FC<PageProps> = () => {
  const [filteredPosts, setFilteredPosts] = React.useState([])
  const [searchedPosts, setSearchedPosts] = React.useState([])
  const [filterCategory, setFilterCategory] = React.useState("")
  const [isSearch, setIsSearch] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("")
  const wpData = useStaticQuery(graphql`
    {
      wpgraphql {
        categories (first: 1000) {
          nodes {
            name
            slug
          }
        }
        posts (first: 1000) {
          nodes {
            date
            slug
            uri
            title(format: RENDERED)
            content(format: RENDERED)
            featuredImage {
              node {
                altText
                mediaItemUrl
              }
            }
            categories (first: 1000) {
              nodes {
                name
                slug
              }
            }
            author {
              node {
                name
                avatar {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)
  let posts: [] = wpData.wpgraphql.posts.nodes
  let categories: [] = wpData.wpgraphql.categories.nodes
  let sortedPosts = arraySort(posts, "date", { reverse: true })

  const viewAll = (category: string) => {
    let requiredCat: categoryType = categories.find((cat: categoryType) => cat.slug === category)
    setFilterCategory(requiredCat.name)
    let filteredPosts = posts.filter((post: postType) => post.categories.nodes.find((postCategory: categoryType) => postCategory.slug === category))
    setFilteredPosts(filteredPosts)
    if(isBrowser) window.scroll(0, 0)
  }
  const clearFilter = () => {
    setFilteredPosts([])
  }
  const searchInPosts = (query: string) => {
    setIsSearch(true)
    setSearchQuery(query);
    if (query.length > 2) {
      let searchedPosts = posts.filter((item: postType) => item.title.toLowerCase().includes(query.toLowerCase()))
      setSearchedPosts(searchedPosts)
    } else if (query.length === 0) {
      setIsSearch(false)
    } else {
      setSearchedPosts([...posts])
    }
  }
  const clearSearchResults = () => {
    setSearchQuery("");
    setIsSearch(false)
  }

  return (
    <Layout>
      <div>
        <Seo title="WeDevelop | Blog" />
        <div className={`${filteredPosts.length === 0 && !isSearch ? "relative pb-16 lg:pb-32 mb-8 lg:mb-16" : "absolute w-full "}`}>
          {filteredPosts.length === 0 && !isSearch && <img className="w-full h-full object-cover left-0 right-0 bottom-0 absolute z-0" src={FeaturedHero} alt="hero decoration" />}
          <div className="container mx-auto px-6 pt-4 relative z-10">
            {/* all categories on top */}
            <CategoryHeader
              data={{
                isSearch: isSearch,
                isFilter: filteredPosts.length !== 0,
                categories: categories.filter((category: categoryType) => posts.find((post: postType) => post.categories.nodes.find((postCategory: categoryType) => postCategory.slug === category.slug))),
                handleSearch: searchInPosts,
                handleClearSearch: clearSearchResults,
                handleClick: clearFilter,
                searchQuery: searchQuery
              }}
            />
            {/* --------------------- */}
            {/* featured section */}
            {filteredPosts.length === 0 && !isSearch && (
              <>
                <Header layout="left" data={{ category: { name: "Latest posts" }, tag: "Featured" }} handleClick={viewAll} />
                <div className="grid lg:grid-cols-2 gap-6">
                  {sortedPosts.length === 0 && <p>No posts found</p>}
                  {sortedPosts.length > 0 &&
                    sortedPosts.slice(0, 3).map((post, idx) => {
                      return (
                        <Card
                          key={idx}
                          colSpan={idx === 0}
                          size={idx === 0 ? "square" : "rectangle"}
                          data={{
                            slug: post.slug,
                            uri: post.uri,
                            title: `${post.title.slice(0, 40)} ...`,
                            categories: post.categories.nodes,
                            author: post.author,
                            image: post.featuredImage.node,
                          }}
                        />
                      )
                    })}
                </div>
              </>
            )}
          </div>
        </div>
        {/* -------------------- */}

        {/* category posts or search results view */}
        {(filteredPosts.length !== 0 || isSearch) && (
          <FilteredPosts
            data={{
              isSearch: isSearch,
              isFilter: filteredPosts.length > 0,
              category: filterCategory,
              categories: categories.filter((category: categoryType) => posts.find((post: postType) => post.categories.nodes.find((postCategory: categoryType) => postCategory.slug === category.slug))),
              filteredPosts: filteredPosts.length !== 0 ? filteredPosts : searchedPosts,
              clearSearchResults: clearSearchResults
            }}
          />
        )}
        {/* ------------------------------ */}
        {/* all sections of the page based on categories */}
        {filteredPosts.length === 0 && !isSearch && (
          <div className="relative overflow-x-hidden">
            {categories.map((category: categoryType, idx) => {
              let filteredPosts = posts.filter((post: postType) => post.categories.nodes.find((postCategory: categoryType) => postCategory.slug === category.slug))
              let matchingPosts = filteredPosts.slice(0, 6)
              return matchingPosts.length > 0 && (
                <div className={idx !== 0 ? "mt-10 relative pb-10 lg:pb-20" : "relative pb-10 lg:pb-20"} key={idx}>
                  {/* Decoration items */}
                  {idx % 2 === 0 && matchingPosts.length > 0 && <img className="w-full absolute bottom-0 left-0 right-0" src={oddBg} />}
                  {idx % 2 === 0 && matchingPosts.length > 3 && <img style={{ top: "30%" }} className="absolute left-0" src={leftOddDeco} />}
                  <div className="container mx-auto px-6 relative">
                    {idx % 2 !== 0 && matchingPosts.length > 0 && <img style={{ top: "43%", left: "-5%" }} className="hidden lg:block absolute left-0" src={evenDots} />}
                    {idx % 2 !== 0 && matchingPosts.length > 0 && <img style={{ bottom: "-16%", right: "-4%" }} className="absolute" src={rightEvenDeco} />}
                    <Header
                      layout="left"
                      data={{
                        category: category,
                        tag: "Projects",
                        button: {
                          data: {
                            text: filteredPosts.length > 6 && "View All",
                          },
                          buttonType: "primary",
                          accent: "Transparent",
                          type: "button",
                        },
                      }}
                      handleClick={viewAll}
                    />
                    <div className={`grid gap-6 ${(matchingPosts.length === 2 || matchingPosts.length === 4) ? "lg:grid-cols-2" : "lg:grid-cols-3"}`}>
                      {matchingPosts.length === 0 && <p>No posts found</p>}
                      {matchingPosts.length > 0 &&
                        matchingPosts.map((post: postType, ind) => {
                          let trimmedTitle = idx % 2 === 0 ? `${post.title.slice(0, 40)} ...` : `${post.title.slice(0, 22)} ...`
                          return (
                            <Card
                              key={ind}
                              size={"square"}
                              data={{
                                slug: post.slug,
                                uri: post.uri,
                                title: trimmedTitle,
                                categories: post.categories.nodes,
                                author: post.author,
                                image: post.featuredImage.node,
                              }}
                            />
                          )
                        })}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}
        {/* -------------------------------------------- */}

        <Newsletter
          data={{
            tag: "Newsletter",
            title: "Subscribe to our newsletter",
          }}
        />
      </div>
    </Layout>
  )
}

export default IndexPage
export interface postType {
  slug: string
  uri: string
  title: string
  content: string
  categories: {
    nodes: [
      {
        name: string
      }
    ]
  }
  author: {
    name: string
  }
  featuredImage: {
    node: {
      mediaItemUrl: string
      alt: string
    }
  }
}

export interface categoryType {
  name: string
  slug?: string
}
